import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import Metadata from "./components/Metadata";

const queryClient = new QueryClient();

// Lazy load components
const Layout = lazy(() => import("./components/Layout/Layout"));
const Home = lazy(() => import("./components/Home/Home"));
const Aboutus = lazy(() => import("./components/Aboutus/Aboutus"));
const Pricing = lazy(() => import("./components/Pricing/Pricing"));
const PreviousWork = lazy(() => import("./components/PreviousWork/PreviousWork"));
const Privacy = lazy(() => import("./components/Privacy/Privacy"));
const Condition = lazy(() => import("./components/Condition/Condition"));
const Projectdetails = lazy(() => import("./components/Projectdetails/Projectdetails"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Blogdetails = lazy(() => import("./components/Blogdetails/Blogdetails"));
const Subscribe = lazy(() => import("./components/Subscribe/Subscribe"));
const Blogs = lazy(() => import("./components/Blogs/Blogs"));

const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Metadata
          title="Home - Code Root"
          description="Welcome to Code Root, your go-to software development company for cutting-edge solutions."
          image="https://cd-root.com/logo.png"
          url="https://cd-root.com"
        />
        <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
          <Layout />
        </Suspense>
      </>
    ),
    children: [
      {
        index: true,
        element: (
          <>
            <Metadata
              title="Home - Code Root"
              description="Welcome to Code Root, your go-to software development company for cutting-edge solutions."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Home />
            </Suspense>
          </>
        ),
      },
      {
        path: "about-us",
        element: (
          <>
            <Metadata
              title="About Us - Code Root"
              description="Learn more about Code Root, our mission, and the innovative solutions we offer."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/about-us"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Aboutus />
            </Suspense>
          </>
        ),
      },
      {
        path: "pricing",
        element: (
          <>
            <Metadata
              title="pricing - Code Root"
              description="Explore our pricing plans and choose the best option for your business needs."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/pricing"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Pricing />
            </Suspense>
          </>
        ),
      },
      {
        path: "previous-work",
        element: (
          <>
            <Metadata
              title="Previous Work - Code Root"
              description="Check out our portfolio of successful projects and software solutions."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/previous-work"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <PreviousWork />
            </Suspense>
          </>
        ),
      },
      {
        path: "privacy",
        element: (
          <>
            <Metadata
              title="Privacy Policy - Code Root"
              description="Read our privacy policy to understand how we handle your data and privacy."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/privacy"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Privacy />
            </Suspense>
          </>
        ),
      },
      {
        path: "condition",
        element: (
          <>
            <Metadata
              title="Terms & Conditions - Code Root"
              description="Read our terms and conditions before using our services."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/condition"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Condition />
            </Suspense>
          </>
        ),
      },
      {
        path: "project-details/:id",
        element: (
          <>
            <Metadata
              title="Project Details - Code Root"
              description="Explore detailed insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/project-details"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Projectdetails />
            </Suspense>
          </>
        ),
      },
      {
        path: "blogs",
        element: (
          <>
            <Metadata
              title="Blogs - Code Root"
              description="Explore Blogs insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/blogs"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Blogs />
            </Suspense>
          </>
        ),
      },
      {
        path: "Blog-details/:id",
        element: (
          <>
            <Metadata
              title="Blogs - Code Root"
              description="Explore Blogs insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/Blog-details"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Blogdetails />
            </Suspense>
          </>
        ),
      },
      {
        path: "Subscribe/:id",
        element: (
          <>
            <Metadata
              title="Subscribe - Code Root"
              description="Explore Subscribe insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/Subscribe"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Subscribe />
            </Suspense>
          </>
        ),
      },
      {
        path: "Subscribe",
        element: (
          <>
            <Metadata
              title="Subscribe - Code Root"
              description="Explore Subscribe insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/Subscribe"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Subscribe />
            </Suspense>
          </>
        ),
      },
      {
        path: "Contact",
        element: (
          <>
            <Metadata
              title="Contact - Code Root"
              description="Explore Contact insights into our recent software development projects."
              image="https://cd-root.com/logo.png"
              url="https://cd-root.com/Contact"
            />
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Contact />
            </Suspense>
          </>
        ),
      },
      {
        path: "*",
        element: (
          <>
            <Suspense fallback={<div className="flex justify-center items-center h-[100vh]"><span className="loader my-8"></span></div>}>
              <Home />
            </Suspense>
          </>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routes} />
    </QueryClientProvider>
  );
}

export default App;
