import { Helmet } from "react-helmet";

const Metadata = ({ title, description, image, url }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content="Software Development, Web Development, Code Root, Programming, IT Services" />
            <meta name="author" content="Code Root" />

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image || "https://cd-root.com/default-image.png"} />
            <meta property="og:url" content={url} />
            <meta property="og:site_name" content="Code Root" />

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image || "https://cd-root.com/default-image.png"} />
            <meta name="twitter:site" content="@coderoot" />

            {/* Performance Optimization */}
            <link rel="preconnect" href="https://cd-root.com" />
            <link rel="dns-prefetch" href="https://cd-root.com" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />

            {/* Favicon */}
            <link rel="icon" href="/favicon-96x96.png" />
            <link rel="apple-touch-icon" href="/apple-icon.png" />
        </Helmet>
    );
};

export default Metadata;
